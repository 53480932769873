import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Colors } from "../../constants/Colors";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInput from "../../components/Input";
import { apiRequest } from "../../services/api-request";
import CustomButton from "../../components/Button";
import { useNavigate } from "react-router-dom";

const AddAnimal = (props) => {
  
  const navigate=useNavigate()

  const schema = yup
    .object({
      mobile: yup.string().required("Mobile Number is required"),
      password: yup.string().required("Password is required"),
    })
    .required();

  useEffect(() => {

  }, []);

  const mithunDefaultValues = () => {
    return {
      mobile: "",
      password: "",
    };
  };

  const { handleSubmit, control, clearErrors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: mithunDefaultValues(),
  });

  const onSubmit = (data) => {
    const payload = {
      mobile: data?.mobile,
      password: data?.password
    };

    apiRequest({
      url: `user/add-muzzle-farmer`,
      data: payload,
      method: "POST",
    })
      .then((res) => {
        reset();
        if (res?.success === true) {
          navigate(`/muzzle`)
          alert("Muzzle User added successfully");
        } else if (res?.success === false) {
          alert(res?.message);
        }
      })
      .catch((err) => {
        alert("Something Went Wrong")
      });
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          backgroundColor={Colors.white}
          boxShadow={"0px 0px 48px #00183414"}
          borderRadius={"5px"}
          p={2}
          margin={1}
        >
          <Box>
            <Grid container mt={2} spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="mobile"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      type={"text"}
                      inputLabel={"Enter Mobile Number"}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomInput
                      {...field}
                      type={"text"}
                      inputLabel={"Enter Password"}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          mt={4}
          gap={3}
        >
          <Grid item>
            <CustomButton
              title={`Save`}
              backgroundColor={Colors.headerColor}
              textColor={Colors.white}
              width={130}
              height={34}
              borderColor={Colors.headerColor}
              borderRadius={2}
              textFontSize={14}
              type={"submit"}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddAnimal;
