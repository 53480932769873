import React, {Component} from 'react'
import {
  CRow,
  CCol,
  CButton,
  CCard,
  CCardBody,
  //CCardFooter,
  CFormGroup,
  CForm,
  CLabel,
  CInput,
  CSelect,
  CFormText,
  CNav,
  CNavItem,
  CNavLink,
  CTabs,
  CTabPane,
  CTabContent,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from '@coreui/react'
import { apiRequest } from "../../services/api-request";
import _ from "underscore";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./dateStyles.css";
import "@coreui/coreui-pro/scss/coreui.scss";

let staticObj = {age_factor:[], milk_yield_factor:[], breed_factor:[], pregnancy_factor:[], pregnancy_period_factor:[], calvings_factor:[], balance_milk_yield_factor:[], live_weight_factor:[]}
class add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields:{},
            errors:{},
            showPurpose: false,
            categories:[],
            purpose:[],
            factorFields: staticObj,

            openAddNew: false,
            newFactorObj:{},
            add_value:'',

            openAddNewLiveWeight: false,
            from_value:'',
            to_value:''
        };
    }

    componentDidMount(){
        apiRequest({
            url: 'master/get-animal-categories',
            method: "GET",
        }).then((res) => {
            this.setState({ categories: res.data});
        })
        .catch((err) => {
            console.error("Error fetching states:", err?.response?.data?.message || err.message);
            alert(err?.response?.data?.message || "Failed to fetch states");
        });
    }

    handleChange(field, e) {
        let fields = this.state.fields
        fields[field] = e.target.value;
        if(field === 'category_id'){
            fields["purpose"] = ''
            let val = _.where(this.state.categories, {id: e.target.value})
            if(val.length > 0){
                val = val[0]
                fields['display_name'] = val.display_name
                if (val.display_name === 'Cattle'){
                    this.setState({purpose: [{label:'Milk', value:'milk'}]})
                } else if (val.display_name === 'Buffalo'){
                    this.setState({purpose: [{label:'Milk', value:'milk'}, {label:'Meat', value:'meat'}]})
                }else{
                    this.setState({purpose: [{label:'Meat', value:'meat'}]})
                }
            }
        }
        if(field === 'purpose'){
            fields["stage"] = ''
            this.setState({factorFields: staticObj})
        }
        if(field === 'stage'){
            this.setState({factorFields: staticObj})
        }
    
        this.setState({ fields }, () => {
            if(field === 'purpose'){
                if(fields.category_id && e.target.value === 'meat'){
                    this.getFactorData()
                }
            }
            if(field === 'stage'){
                if(fields.category_id && fields.purpose && e.target.value){
                    this.getFactorData()
                }
            }
        })
    }

    getFactorData(){
        let fields = this.state.fields
        apiRequest({
            url: 'master/get-factor-values',
            method: "POST",
            data: JSON.stringify(fields)
        }).then((res) => {
            if(res.data === null || res.data === 'null'){
                let factorFields = this.state.factorFields

                let age_factor = []
                _.each(_.range(1, 11), (cnt) => {
                     age_factor.push({age: cnt, factor_val: 0})
                })
                factorFields.age_factor = age_factor

                if((fields.display_name === 'Buffalo' || fields.display_name === 'Sheep' || fields.display_name === 'Goat') && fields.purpose === 'meat'){
                    factorFields['value_per_kg'] = 0
                    factorFields['male_factor'] = 0
                    factorFields['female_factor'] = 0
                    factorFields['carcass_yield_male'] = 0
                    factorFields['carcass_yield_female'] = 0

                    let live_weight_factor = []
                    if(fields.display_name === 'Buffalo'){
                        live_weight_factor.push({live_weight: "20-100", factor_val: 0})
                        live_weight_factor.push({live_weight: "101-200", factor_val: 0})
                        live_weight_factor.push({live_weight: "201-300", factor_val: 0})
                        live_weight_factor.push({live_weight: "301-400", factor_val: 0})
                        live_weight_factor.push({live_weight: "401-500", factor_val: 0})
                        live_weight_factor.push({live_weight: "501-550", factor_val: 0})
                        live_weight_factor.push({live_weight: "551-600", factor_val: 0})
                    }else{
                        live_weight_factor.push({live_weight: "5-11", factor_val: 0})
                        live_weight_factor.push({live_weight: "12-15", factor_val: 0})
                        live_weight_factor.push({live_weight: "16-24", factor_val: 0})
                        live_weight_factor.push({live_weight: "25-29", factor_val: 0})
                        live_weight_factor.push({live_weight: "30-39", factor_val: 0})
                        live_weight_factor.push({live_weight: "40-50", factor_val: 0})
                    }
                    factorFields.live_weight_factor = live_weight_factor
                }

                if((fields.display_name === 'Buffalo' || fields.display_name === 'Cattle') && fields.purpose === 'milk' && fields.stage === 'heifer'){
                    factorFields['value_per_litre'] = 0

                    let milk_yield_factor = []
                    _.each(_.range(1, 21), (cnt) => {
                         milk_yield_factor.push({milk_yield: cnt, factor_val: 0})
                    })
                    factorFields.milk_yield_factor = milk_yield_factor

                    let pregnancy_factor = [{pregnancy: 'Yes', factor_val: 0}, {pregnancy: 'No', factor_val: 0}]
                    factorFields.pregnancy_factor = pregnancy_factor

                    let pregnancy_period_factor = []
                    _.each(_.range(1, 11), (cnt) => {
                         pregnancy_period_factor.push({pregnancy_period: cnt, factor_val: 0})
                    })
                    factorFields.pregnancy_period_factor = pregnancy_period_factor
                }

                if((fields.display_name === 'Buffalo' || fields.display_name === 'Cattle') && fields.purpose === 'milk' && fields.stage === 'already_calved'){
                    factorFields['value_per_litre'] = 0
                    factorFields['fat_percentage_factor'] = 0

                    let milk_yield_factor = []
                    _.each(_.range(1, 21), (cnt) => {
                         milk_yield_factor.push({milk_yield: cnt, factor_val: 0})
                    })
                    factorFields.milk_yield_factor = milk_yield_factor

                    let calvings_factor = []
                    _.each(_.range(0, 6), (cnt) => {
                         calvings_factor.push({calvings: cnt, factor_val: 0})
                    })
                    factorFields.calvings_factor = calvings_factor

                    let balance_milk_yield_factor = []
                    _.each(_.range(1, 11), (cnt) => {
                         balance_milk_yield_factor.push({balance_milk_yield: cnt, factor_val: 0})
                    })
                    factorFields.balance_milk_yield_factor = balance_milk_yield_factor
                }

                if((fields.display_name === 'Buffalo' || fields.display_name === 'Cattle') && fields.purpose === 'milk'){
                    apiRequest({
                        url: "master/get-breeds?category_id="+fields.category_id,
                        method: "GET",
                    }).then((data) => {
                        let breed_factor = []
                        _.each(data.data, (elem) => {
                             breed_factor.push({name: elem.display_name, breed_id: elem.id, factor_val: 0})
                        })
                        factorFields.breed_factor = breed_factor
                        this.setState({ factorFields })
                    })
                    .catch((err) => {
                        console.error("Error fetching states:", err?.response?.data?.message || err.message);
                        alert(err?.response?.data?.message || "Failed to fetch states");
                    });
                }
                else{
                    this.setState({ factorFields })
                }
            }
            else{
                delete res.data.category_id
                delete res.data.display_name
                delete res.data.purpose
                delete res.data.stage

                this.setState({factorFields: res.data})
            }
        })
        .catch((err) => {
            console.error("Error fetching states:", err?.response?.data?.message || err.message);
            alert(err?.response?.data?.message || "Failed to fetch states");
        });
    }

    handleChangeFactor(field, e){
        let factorFields = this.state.factorFields
        factorFields[field] = e.target.value;
        this.setState({ factorFields })
    }

    handleChangeAllFactor(field, factor, key, e){
        let factorFields = this.state.factorFields
        let values = factorFields[factor]
        _.each(values, (elem) => {
            if(elem[key] === field){
                elem.factor_val = parseFloat(e.target.value)
            }
        })
        factorFields[factor] = values
        this.setState({ factorFields })
    }

    submit(){
        let fields = this.state.fields
        fields = {...fields, ...this.state.factorFields};
        apiRequest({
            url: 'master/update-factor-values',
            method: "POST",
            data: JSON.stringify(fields)
        }).then((res) => {
            fields['category_id'] = ''
            fields['display_name'] = ''
            fields['purpose'] = ''
            fields['stage'] = ''
            this.setState({fields, factorFields: staticObj})
        })
        .catch((err) => {
            console.error("Error fetching states:", err?.response?.data?.message || err.message);
            alert(err?.response?.data?.message || "Failed to fetch states");
        });
    }

    addNewField(obj){
        this.setState({newFactorObj: obj, openAddNew: true})
    }

    submitNewField(){
        if(!this.state.add_value){
            return toast.error("Please enter value");
        }
        let factorFields = this.state.factorFields
        let factor_val = factorFields[this.state.newFactorObj.factor]
        let obj = {}
        obj[this.state.newFactorObj.key] = this.state.add_value
        obj['factor_val'] = 0
        factor_val.push(obj)
        factorFields[this.state.newFactorObj.factor] = factor_val
        this.setState({factorFields, openAddNew: false, add_value: ''})
    }

    submitLiveWeightFactor(){
        if(!this.state.from_value){
            return toast.error("Please enter from value");
        }
        if(!this.state.to_value){
            return toast.error("Please enter to value");
        }
        if(parseFloat(this.state.to_value) < parseFloat(this.state.from_value)){
            return toast.error("To value should be greater then to from value");
        }
        let factorFields = this.state.factorFields
        let factor_val = factorFields['live_weight_factor']
        let obj = {}
        obj['live_weight'] = this.state.from_value+'-'+this.state.to_value
        obj['factor_val'] = 0
        factor_val.push(obj)
        factorFields['live_weight_factor'] = factor_val
        this.setState({factorFields, openAddNewLiveWeight: false, from_value: '', to_value: ''})
    }
   
    render() {
        return (
            <CRow>
                <ToastContainer position="top-right" autoClose={5000} style={{top:'80px'}}/>
                <CModal 
                  show={this.state.openAddNew} 
                  onClose={() => this.setState({openAddNew:!this.state.openAddNew})}
                  color="danger"
                >
                    <CModalHeader closeButton>
                        <CModalTitle>Add New {this.state.newFactorObj.key}</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <CRow>
                            <CCol xs="12" md="12">
                                <CFormGroup row>
                                    <CCol md="12">
                                        <CInput type="number" placeholder="Enter value" onChange={(e)=> this.setState({add_value: e.target.value})} value={this.state.add_value} />
                                    </CCol>
                                </CFormGroup>
                            </CCol>
                        </CRow>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="success" onClick={this.submitNewField.bind(this)}>Add</CButton>
                    </CModalFooter>
                </CModal>

                <CModal 
                  show={this.state.openAddNewLiveWeight} 
                  onClose={() => this.setState({openAddNewLiveWeight:!this.state.openAddNewLiveWeight})}
                  color="danger"
                >
                    <CModalHeader closeButton>
                        <CModalTitle>Add New Live Weight Factor Range</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <CRow>
                            <CCol xs="12" md="12">
                                <CFormGroup row>
                                    <CCol md="12">
                                        <CLabel htmlFor="hf-Status">From</CLabel>
                                        <CInput type="number" placeholder="Enter from value" onChange={(e)=> this.setState({from_value: e.target.value})} value={this.state.from_value} />
                                    </CCol>
                                    <CCol md="12">
                                        <CLabel htmlFor="hf-Status">To</CLabel>
                                        <CInput type="number" placeholder="Enter to value" onChange={(e)=> this.setState({to_value: e.target.value})} value={this.state.to_value} />
                                    </CCol>
                                </CFormGroup>
                            </CCol>
                        </CRow>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="success" onClick={this.submitLiveWeightFactor.bind(this)}>Add</CButton>
                    </CModalFooter>
                </CModal>

                <CCol xs="12" md="12">
                    <CCard>
                        <CCardBody>
                            <CForm className="form-horizontal">
                                <CFormGroup row>
                                    <CCol md="4">
                                        <CLabel htmlFor="hf-Status">Category</CLabel>
                                        <CSelect custom onChange={this.handleChange.bind(this, 'category_id')} value={this.state.fields.category_id}>
                                            <option value="">--Select Category--</option>
                                            {
                                                this.state.categories.map((e, key) => {
                                                    return <option key={key} value={e.id}>{e.display_name}</option>;
                                                })
                                            }
                                        </CSelect>
                                        <CFormText className="help-block">{this.state.errors.category_id}</CFormText>
                                    </CCol>
                                    <CCol md="4">
                                        <CLabel htmlFor="hf-code">Purpose</CLabel>
                                        <CSelect custom onChange={this.handleChange.bind(this, 'purpose')} value={this.state.fields.purpose}>
                                            <option value="">--Select Purpose--</option>
                                            {
                                                this.state.purpose.map((e, key) => {
                                                    return <option key={key} value={e.value}>{e.label}</option>;
                                                })
                                            }
                                        </CSelect>
                                        <CFormText className="help-block">{this.state.errors.purpose}</CFormText>
                                    </CCol>
                                    {
                                        this.state.fields.purpose === 'milk' ?
                                        <CCol md="4">
                                            <CLabel htmlFor="hf-code">Stage</CLabel>
                                            <CSelect custom onChange={this.handleChange.bind(this, 'stage')} value={this.state.fields.stage}>
                                                <option value="">--Select Stage--</option>
                                                <option value="heifer">Heifer</option>
                                                <option value="already_calved">Already Calved</option>
                                            </CSelect>
                                            <CFormText className="help-block">{this.state.errors.stage}</CFormText>
                                        </CCol> : null
                                    }
                                    {
                                        (this.state.fields.display_name === 'Buffalo' || this.state.fields.display_name === 'Sheep' || this.state.fields.display_name === 'Goat') && this.state.fields.purpose === 'meat' ?
                                        <CCol xs="12" md="12" style={{marginTop:'20px'}}>
                                            <CTabs>
                                                <CNav variant="tabs">
                                                    <CNavItem>
                                                        <CNavLink>
                                                            Value Per KG
                                                        </CNavLink>
                                                    </CNavItem>
                                                    <CNavItem>
                                                        <CNavLink>
                                                            Age Factor
                                                        </CNavLink>
                                                    </CNavItem>
                                                    <CNavItem>
                                                        <CNavLink>
                                                            Gender Factor
                                                        </CNavLink>
                                                    </CNavItem>
                                                    <CNavItem>
                                                        <CNavLink>
                                                            Carcass Yield %
                                                        </CNavLink>
                                                    </CNavItem>
                                                    <CNavItem>
                                                        <CNavLink>
                                                            Live Weight Factor
                                                        </CNavLink>
                                                    </CNavItem>
                                                </CNav>
                                                <CTabContent>
                                                    <CTabPane>
                                                        <CRow>
                                                            <CCol xs="12" md="12">
                                                                <CCard>
                                                                    <CCardBody>
                                                                        <CForm className="form-horizontal">
                                                                            <CFormGroup row>
                                                                                <CCol md="6">
                                                                                    <CLabel htmlFor="hf-title">Value Per KG</CLabel>
                                                                                    <CInput type="number" placeholder="Enter value" onChange={this.handleChangeFactor.bind(this,'value_per_kg')} value={this.state.factorFields.value_per_kg} />
                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CForm>   
                                                                    </CCardBody>
                                                                </CCard>
                                                            </CCol>
                                                        </CRow>
                                                    </CTabPane>
                                                    <CTabPane>
                                                        <CRow>
                                                            <CCol xs="12" md="12">
                                                                <CCard>
                                                                    <CCardBody>
                                                                        <CForm className="form-horizontal">
                                                                            <CFormGroup row>
                                                                                {
                                                                                    this.state.factorFields.age_factor.map((e, key) => {
                                                                                        return <CCol md="3" key={key}>
                                                                                            <CLabel htmlFor="hf-title">Age - {e.age}</CLabel>
                                                                                            <CInput type="number" placeholder="Enter value" onChange={this.handleChangeAllFactor.bind(this, e.age, 'age_factor', 'age')} value={e.factor_val} />
                                                                                        </CCol>;
                                                                                    })
                                                                                }
                                                                                <CCol md="12" style={{marginTop:'10px'}}>
                                                                                    <CButton color="success" className="px-4" onClick={this.addNewField.bind(this, {factor:'age_factor', key:'age'})}>Add New</CButton>
                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CForm>
                                                                    </CCardBody>
                                                                </CCard>
                                                            </CCol>
                                                        </CRow>
                                                    </CTabPane>
                                                    <CTabPane>
                                                        <CRow>
                                                            <CCol xs="12" md="12">
                                                                <CCard>
                                                                    <CCardBody>
                                                                        <CForm className="form-horizontal">
                                                                            <CFormGroup row>
                                                                                <CCol md="6">
                                                                                    <CLabel htmlFor="hf-title">Male</CLabel>
                                                                                    <CInput type="number" placeholder="Enter value" onChange={this.handleChangeFactor.bind(this,'male_factor')} value={this.state.factorFields.male_factor} />
                                                                                </CCol>
                                                                                <CCol md="6">
                                                                                    <CLabel htmlFor="hf-title">Female</CLabel>
                                                                                    <CInput type="number" placeholder="Enter value" onChange={this.handleChangeFactor.bind(this,'female_factor')} value={this.state.factorFields.female_factor} />
                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CForm>   
                                                                    </CCardBody>
                                                                </CCard>
                                                            </CCol>
                                                        </CRow>
                                                    </CTabPane>
                                                    <CTabPane>
                                                        <CRow>
                                                            <CCol xs="12" md="12">
                                                                <CCard>
                                                                    <CCardBody>
                                                                        <CForm className="form-horizontal">
                                                                            <CFormGroup row>
                                                                                <CCol md="6">
                                                                                    <CLabel htmlFor="hf-title">Carcass Yield Male (%)</CLabel>
                                                                                    <CInput type="number" placeholder="Enter value" onChange={this.handleChangeFactor.bind(this,'carcass_yield_male')} value={this.state.factorFields.carcass_yield_male} />
                                                                                </CCol>
                                                                                <CCol md="6">
                                                                                    <CLabel htmlFor="hf-title">Carcass Yield Female (%)</CLabel>
                                                                                    <CInput type="number" placeholder="Enter value" onChange={this.handleChangeFactor.bind(this,'carcass_yield_female')} value={this.state.factorFields.carcass_yield_female} />
                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CForm>   
                                                                    </CCardBody>
                                                                </CCard>
                                                            </CCol>
                                                        </CRow>
                                                    </CTabPane>
                                                    <CTabPane>
                                                        <CRow>
                                                            <CCol xs="12" md="12">
                                                                <CCard>
                                                                    <CCardBody>
                                                                        <CForm className="form-horizontal">
                                                                            <CFormGroup row>
                                                                                {
                                                                                    this.state.factorFields.live_weight_factor.map((e, key) => {
                                                                                        return <CCol md="3" key={key}>
                                                                                            <CLabel htmlFor="hf-title">Live Factor -> {e.live_weight}</CLabel>
                                                                                            <CInput type="number" placeholder="Enter value" onChange={this.handleChangeAllFactor.bind(this, e.live_weight, 'live_weight_factor', 'live_weight')} value={e.factor_val} />
                                                                                        </CCol>;
                                                                                    })
                                                                                }
                                                                                <CCol md="12" style={{marginTop:'10px'}}>
                                                                                    <CButton color="success" className="px-4" onClick={()=> this.setState({openAddNewLiveWeight: true})}>Add New</CButton>
                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CForm>   
                                                                    </CCardBody>
                                                                </CCard>
                                                            </CCol>
                                                        </CRow>
                                                    </CTabPane>
                                                </CTabContent>
                                            </CTabs>
                                            <CButton color="primary" className="px-4" onClick={this.submit.bind(this)}>Submit</CButton>
                                        </CCol>
                                        : null
                                    }

                                    {
                                        (this.state.fields.display_name === 'Buffalo' || this.state.fields.display_name === 'Cattle') && this.state.fields.purpose === 'milk' && this.state.fields.stage === 'heifer' ?
                                        <CCol xs="12" md="12" style={{marginTop:'20px'}}>
                                            <CTabs>
                                                <CNav variant="tabs">
                                                    <CNavItem>
                                                        <CNavLink>
                                                            Value Per Litre
                                                        </CNavLink>
                                                    </CNavItem>
                                                    <CNavItem>
                                                        <CNavLink>
                                                            Milk Yield Factor
                                                        </CNavLink>
                                                    </CNavItem>
                                                    <CNavItem>
                                                        <CNavLink>
                                                            Breed Factor
                                                        </CNavLink>
                                                    </CNavItem>
                                                    <CNavItem>
                                                        <CNavLink>
                                                            Age Factor
                                                        </CNavLink>
                                                    </CNavItem>
                                                    <CNavItem>
                                                        <CNavLink>
                                                            Pregnancy Factor
                                                        </CNavLink>
                                                    </CNavItem>
                                                    <CNavItem>
                                                        <CNavLink>
                                                            Pregnancy Period Factor
                                                        </CNavLink>
                                                    </CNavItem>
                                                </CNav>
                                                <CTabContent>
                                                    <CTabPane>
                                                        <CRow>
                                                            <CCol xs="12" md="12">
                                                                <CCard>
                                                                    <CCardBody>
                                                                        <CForm className="form-horizontal">
                                                                            <CFormGroup row>
                                                                                <CCol md="6">
                                                                                    <CLabel htmlFor="hf-title">Value Per Litre</CLabel>
                                                                                    <CInput type="number" placeholder="Enter value" onChange={this.handleChangeFactor.bind(this,'value_per_litre')} value={this.state.factorFields.value_per_litre} />
                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CForm>   
                                                                    </CCardBody>
                                                                </CCard>
                                                            </CCol>
                                                        </CRow>
                                                    </CTabPane>
                                                    <CTabPane>
                                                        <CRow>
                                                            <CCol xs="12" md="12">
                                                                <CCard>
                                                                    <CCardBody>
                                                                        <CForm className="form-horizontal">
                                                                            <CFormGroup row>
                                                                                {
                                                                                    this.state.factorFields.milk_yield_factor.map((e, key) => {
                                                                                        return <CCol md="3" key={key}>
                                                                                            <CLabel htmlFor="hf-title">Milk Yield - {e.milk_yield}</CLabel>
                                                                                            <CInput type="number" placeholder="Enter value" onChange={this.handleChangeAllFactor.bind(this, e.milk_yield, 'milk_yield_factor', 'milk_yield')} value={e.factor_val} />
                                                                                        </CCol>;
                                                                                    })
                                                                                }
                                                                                <CCol md="12" style={{marginTop:'10px'}}>
                                                                                    <CButton color="success" className="px-4" onClick={this.addNewField.bind(this, {factor:'milk_yield_factor', key:'milk_yield'})}>Add New</CButton>
                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CForm>
                                                                    </CCardBody>
                                                                </CCard>
                                                            </CCol>
                                                        </CRow>
                                                    </CTabPane>
                                                    <CTabPane>
                                                        <CRow>
                                                            <CCol xs="12" md="12">
                                                                <CCard>
                                                                    <CCardBody>
                                                                        <CForm className="form-horizontal">
                                                                            <CFormGroup row>
                                                                                {
                                                                                    this.state.factorFields.breed_factor.map((e, key) => {
                                                                                        return <CCol md="3" key={key}>
                                                                                            <CLabel htmlFor="hf-title">{e.name}</CLabel>
                                                                                            <CInput type="number" placeholder="Enter value" onChange={this.handleChangeAllFactor.bind(this, e.breed_id, 'breed_factor', 'breed_id')} value={e.factor_val} />
                                                                                        </CCol>;
                                                                                    })
                                                                                }
                                                                            </CFormGroup>
                                                                        </CForm>
                                                                    </CCardBody>
                                                                </CCard>
                                                            </CCol>
                                                        </CRow>
                                                    </CTabPane>
                                                    <CTabPane>
                                                        <CRow>
                                                            <CCol xs="12" md="12">
                                                                <CCard>
                                                                    <CCardBody>
                                                                        <CForm className="form-horizontal">
                                                                            <CFormGroup row>
                                                                                {
                                                                                    this.state.factorFields.age_factor.map((e, key) => {
                                                                                        return <CCol md="3" key={key}>
                                                                                            <CLabel htmlFor="hf-title">Age - {e.age}</CLabel>
                                                                                            <CInput type="number" placeholder="Enter value" onChange={this.handleChangeAllFactor.bind(this, e.age, 'age_factor', 'age')} value={e.factor_val} />
                                                                                        </CCol>;
                                                                                    })
                                                                                }
                                                                                <CCol md="12" style={{marginTop:'10px'}}>
                                                                                    <CButton color="success" className="px-4" onClick={this.addNewField.bind(this, {factor:'age_factor', key:'age'})}>Add New</CButton>
                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CForm>
                                                                    </CCardBody>
                                                                </CCard>
                                                            </CCol>
                                                        </CRow>
                                                    </CTabPane>
                                                    <CTabPane>
                                                        <CRow>
                                                            <CCol xs="12" md="12">
                                                                <CCard>
                                                                    <CCardBody>
                                                                        <CForm className="form-horizontal">
                                                                            <CFormGroup row>
                                                                                {
                                                                                    this.state.factorFields.pregnancy_factor.map((e, key) => {
                                                                                        return <CCol md="3" key={key}>
                                                                                            <CLabel htmlFor="hf-title">{e.pregnancy}</CLabel>
                                                                                            <CInput type="number" placeholder="Enter value" onChange={this.handleChangeAllFactor.bind(this, e.pregnancy, 'pregnancy_factor', 'pregnancy')} value={e.factor_val} />
                                                                                        </CCol>;
                                                                                    })
                                                                                }
                                                                            </CFormGroup>
                                                                        </CForm>   
                                                                    </CCardBody>
                                                                </CCard>
                                                            </CCol>
                                                        </CRow>
                                                    </CTabPane>
                                                    <CTabPane>
                                                        <CRow>
                                                            <CCol xs="12" md="12">
                                                                <CCard>
                                                                    <CCardBody>
                                                                        <CForm className="form-horizontal">
                                                                            <CFormGroup row>
                                                                                {
                                                                                    this.state.factorFields.pregnancy_period_factor.map((e, key) => {
                                                                                        return <CCol md="3" key={key}>
                                                                                            <CLabel htmlFor="hf-title">Pregnancy Period - {e.pregnancy_period}</CLabel>
                                                                                            <CInput type="number" placeholder="Enter value" onChange={this.handleChangeAllFactor.bind(this, e.pregnancy_period, 'pregnancy_period_factor', 'pregnancy_period')} value={e.factor_val} />
                                                                                        </CCol>;
                                                                                    })
                                                                                }
                                                                                <CCol md="12" style={{marginTop:'10px'}}>
                                                                                    <CButton color="success" className="px-4" onClick={this.addNewField.bind(this, {factor:'pregnancy_period_factor', key:'pregnancy_period'})}>Add New</CButton>
                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CForm>   
                                                                    </CCardBody>
                                                                </CCard>
                                                            </CCol>
                                                        </CRow>
                                                    </CTabPane>
                                                </CTabContent>
                                            </CTabs>
                                            <CButton color="primary" className="px-4" onClick={this.submit.bind(this)}>Submit</CButton>
                                        </CCol>
                                        : null
                                    }

                                    {
                                        (this.state.fields.display_name === 'Buffalo' || this.state.fields.display_name === 'Cattle') && this.state.fields.purpose === 'milk' && this.state.fields.stage === 'already_calved' ?
                                        <CCol xs="12" md="12" style={{marginTop:'20px'}}>
                                            <CTabs>
                                                <CNav variant="tabs">
                                                    <CNavItem>
                                                        <CNavLink>
                                                            Value Per Litre
                                                        </CNavLink>
                                                    </CNavItem>
                                                    <CNavItem>
                                                        <CNavLink>
                                                            Milk Yield Factor
                                                        </CNavLink>
                                                    </CNavItem>
                                                    <CNavItem>
                                                        <CNavLink>
                                                            Breed Factor
                                                        </CNavLink>
                                                    </CNavItem>
                                                    <CNavItem>
                                                        <CNavLink>
                                                            Age Factor
                                                        </CNavLink>
                                                    </CNavItem>
                                                    <CNavItem>
                                                        <CNavLink>
                                                            Calvings Factor
                                                        </CNavLink>
                                                    </CNavItem>
                                                    <CNavItem>
                                                        <CNavLink>
                                                            Balance Milk Yield Factor
                                                        </CNavLink>
                                                    </CNavItem>
                                                    <CNavItem>
                                                        <CNavLink>
                                                            Fat Percentage Value
                                                        </CNavLink>
                                                    </CNavItem>
                                                </CNav>
                                                <CTabContent>
                                                    <CTabPane>
                                                        <CRow>
                                                            <CCol xs="12" md="12">
                                                                <CCard>
                                                                    <CCardBody>
                                                                        <CForm className="form-horizontal">
                                                                            <CFormGroup row>
                                                                                <CCol md="6">
                                                                                    <CLabel htmlFor="hf-title">Value Per Litre</CLabel>
                                                                                    <CInput type="number" placeholder="Enter value" onChange={this.handleChangeFactor.bind(this,'value_per_litre')} value={this.state.factorFields.value_per_litre} />
                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CForm>   
                                                                    </CCardBody>
                                                                </CCard>
                                                            </CCol>
                                                        </CRow>
                                                    </CTabPane>
                                                    <CTabPane>
                                                        <CRow>
                                                            <CCol xs="12" md="12">
                                                                <CCard>
                                                                    <CCardBody>
                                                                        <CForm className="form-horizontal">
                                                                            <CFormGroup row>
                                                                                {
                                                                                    this.state.factorFields.milk_yield_factor.map((e, key) => {
                                                                                        return <CCol md="3" key={key}>
                                                                                            <CLabel htmlFor="hf-title">Milk Yield - {e.milk_yield}</CLabel>
                                                                                            <CInput type="number" placeholder="Enter value" onChange={this.handleChangeAllFactor.bind(this, e.milk_yield, 'milk_yield_factor', 'milk_yield')} value={e.factor_val} />
                                                                                        </CCol>;
                                                                                    })
                                                                                }
                                                                                <CCol md="12" style={{marginTop:'10px'}}>
                                                                                    <CButton color="success" className="px-4" onClick={this.addNewField.bind(this, {factor:'milk_yield_factor', key:'milk_yield'})}>Add New</CButton>
                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CForm>
                                                                    </CCardBody>
                                                                </CCard>
                                                            </CCol>
                                                        </CRow>
                                                    </CTabPane>
                                                    <CTabPane>
                                                        <CRow>
                                                            <CCol xs="12" md="12">
                                                                <CCard>
                                                                    <CCardBody>
                                                                        <CForm className="form-horizontal">
                                                                            <CFormGroup row>
                                                                                {
                                                                                    this.state.factorFields.breed_factor.map((e, key) => {
                                                                                        return <CCol md="3" key={key}>
                                                                                            <CLabel htmlFor="hf-title">{e.name}</CLabel>
                                                                                            <CInput type="number" placeholder="Enter value" onChange={this.handleChangeAllFactor.bind(this, e.breed_id, 'breed_factor', 'breed_id')} value={e.factor_val} />
                                                                                        </CCol>;
                                                                                    })
                                                                                }
                                                                            </CFormGroup>
                                                                        </CForm>
                                                                    </CCardBody>
                                                                </CCard>
                                                            </CCol>
                                                        </CRow>
                                                    </CTabPane>
                                                    <CTabPane>
                                                        <CRow>
                                                            <CCol xs="12" md="12">
                                                                <CCard>
                                                                    <CCardBody>
                                                                        <CForm className="form-horizontal">
                                                                            <CFormGroup row>
                                                                                {
                                                                                    this.state.factorFields.age_factor.map((e, key) => {
                                                                                        return <CCol md="3" key={key}>
                                                                                            <CLabel htmlFor="hf-title">Age - {e.age}</CLabel>
                                                                                            <CInput type="number" placeholder="Enter value" onChange={this.handleChangeAllFactor.bind(this, e.age, 'age_factor', 'age')} value={e.factor_val} />
                                                                                        </CCol>;
                                                                                    })
                                                                                }
                                                                                <CCol md="12" style={{marginTop:'10px'}}>
                                                                                    <CButton color="success" className="px-4" onClick={this.addNewField.bind(this, {factor:'age_factor', key:'age'})}>Add New</CButton>
                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CForm>
                                                                    </CCardBody>
                                                                </CCard>
                                                            </CCol>
                                                        </CRow>
                                                    </CTabPane>
                                                    <CTabPane>
                                                        <CRow>
                                                            <CCol xs="12" md="12">
                                                                <CCard>
                                                                    <CCardBody>
                                                                        <CForm className="form-horizontal">
                                                                            <CFormGroup row>
                                                                                {
                                                                                    this.state.factorFields.calvings_factor.map((e, key) => {
                                                                                        return <CCol md="3" key={key}>
                                                                                            <CLabel htmlFor="hf-title">Calvings - {e.calvings}</CLabel>
                                                                                            <CInput type="number" placeholder="Enter value" onChange={this.handleChangeAllFactor.bind(this, e.calvings, 'calvings_factor', 'calvings')} value={e.factor_val} />
                                                                                        </CCol>;
                                                                                    })
                                                                                }
                                                                                <CCol md="12" style={{marginTop:'10px'}}>
                                                                                    <CButton color="success" className="px-4" onClick={this.addNewField.bind(this, {factor:'calvings_factor', key:'calvings'})}>Add New</CButton>
                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CForm>   
                                                                    </CCardBody>
                                                                </CCard>
                                                            </CCol>
                                                        </CRow>
                                                    </CTabPane>
                                                    <CTabPane>
                                                        <CRow>
                                                            <CCol xs="12" md="12">
                                                                <CCard>
                                                                    <CCardBody>
                                                                        <CForm className="form-horizontal">
                                                                            <CFormGroup row>
                                                                                {
                                                                                    this.state.factorFields.balance_milk_yield_factor.map((e, key) => {
                                                                                        return <CCol md="3" key={key}>
                                                                                            <CLabel htmlFor="hf-title">Balance Milk Yield - {e.balance_milk_yield}</CLabel>
                                                                                            <CInput type="number" placeholder="Enter value" onChange={this.handleChangeAllFactor.bind(this, e.balance_milk_yield, 'balance_milk_yield_factor', 'balance_milk_yield')} value={e.factor_val} />
                                                                                        </CCol>;
                                                                                    })
                                                                                }
                                                                                <CCol md="12" style={{marginTop:'10px'}}>
                                                                                    <CButton color="success" className="px-4" onClick={this.addNewField.bind(this, {factor:'balance_milk_yield_factor', key:'balance_milk_yield'})}>Add New</CButton>
                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CForm>   
                                                                    </CCardBody>
                                                                </CCard>
                                                            </CCol>
                                                        </CRow>
                                                    </CTabPane>
                                                    <CTabPane>
                                                        <CRow>
                                                            <CCol xs="12" md="12">
                                                                <CCard>
                                                                    <CCardBody>
                                                                        <CForm className="form-horizontal">
                                                                            <CFormGroup row>
                                                                                <CCol md="6">
                                                                                    <CLabel htmlFor="hf-title">Fat Percentage Factor Value</CLabel>
                                                                                    <CInput type="number" placeholder="Enter value" onChange={this.handleChangeFactor.bind(this,'fat_percentage_factor')} value={this.state.factorFields.fat_percentage_factor} />
                                                                                </CCol>
                                                                            </CFormGroup>
                                                                        </CForm>   
                                                                    </CCardBody>
                                                                </CCard>
                                                            </CCol>
                                                        </CRow>
                                                    </CTabPane>
                                                </CTabContent>
                                            </CTabs>
                                            <CButton color="primary" className="px-4" onClick={this.submit.bind(this)}>Submit</CButton>
                                        </CCol>
                                        : null
                                    }
                                    
                                </CFormGroup>
                              </CForm>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>      
        )
    }
}

export default add
