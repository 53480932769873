// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-primary{
    background-color: #B1040E !important;
    border-color: #B1040E !important;
    font-size: 15px !important;
    padding: 6px 25px !important;
    margin-right: 10px !important;
}
.btn-primary:hover{
  background-color: #B1040E !important;
}
.btn-primary:focus, .btn-primary.focus {
    background-color: #B1040E !important;
    border-color: #B1040E !important;
}
.text-muted{color: red !important; font-size: 14px;}
small.form-text.text-muted.help-block{
    position: relative;
    bottom: 10px;
}
.nav-item a{
    color: #B1040E !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/AnimalPriceData/dateStyles.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,gCAAgC;IAChC,0BAA0B;IAC1B,4BAA4B;IAC5B,6BAA6B;AACjC;AACA;EACE,oCAAoC;AACtC;AACA;IACI,oCAAoC;IACpC,gCAAgC;AACpC;AACA,YAAY,qBAAqB,EAAE,eAAe,CAAC;AACnD;IACI,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".btn-primary{\n    background-color: #B1040E !important;\n    border-color: #B1040E !important;\n    font-size: 15px !important;\n    padding: 6px 25px !important;\n    margin-right: 10px !important;\n}\n.btn-primary:hover{\n  background-color: #B1040E !important;\n}\n.btn-primary:focus, .btn-primary.focus {\n    background-color: #B1040E !important;\n    border-color: #B1040E !important;\n}\n.text-muted{color: red !important; font-size: 14px;}\nsmall.form-text.text-muted.help-block{\n    position: relative;\n    bottom: 10px;\n}\n.nav-item a{\n    color: #B1040E !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
